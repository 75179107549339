// src/components/Navbar.jsx

import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DarkModeContext } from '../context/DarkModeContext';
import DarkModeToggle from './DarkModeToggle';
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  CurrencyDollarIcon,
  ServerStackIcon,
  PresentationChartLineIcon,
  ChartBarIcon,
  UserGroupIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  // Toggle mobile menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const dashboardText = document.getElementById('nosana-dashboard');
    if (dashboardText) {
      dashboardText.classList.add('fade-in');
    }
  }, []);

  return (
    <header className="bg-gray-100 dark:bg-gray-800 shadow-md py-4 z-50">
      <nav className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <Link
          to="/"
          id="nosana-dashboard"
          className="text-xl font-bold text-gray-900 dark:text-white fade-in flex items-center space-x-2"
        >
          <img src="file.png" alt="Logo" className="w-9 h-9" />
          <span>Nos+</span>
        </Link>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6 items-center">
          <NavItem to="/" label="Dashboard" icon={<HomeIcon className="w-5 h-5" />} />
          <NavItem to="/nosana-prices" label="Prices" icon={<CurrencyDollarIcon className="w-5 h-5" />} />
          <NavItem to="/nosana-volume" label="Volume" icon={<ChartBarIcon className="w-5 h-5" />} />
          <NavItem to="/nosana-holders" label="Holders" icon={<UserGroupIcon className="w-5 h-5" />} />

          {/* Dropdown for Staking */}
          <DropdownMenu
            label="Staking"
            icon={<ServerStackIcon className="w-5 h-5" />}
            isOpen={openDropdown === 'staking'}
            onToggle={() => setOpenDropdown(openDropdown === 'staking' ? null : 'staking')}
          >
            <DropdownItem to="/staking-dapp" label="Staking Dapp" />
            <DropdownItem to="/nos-staked" label="Staked $NOS" />
            <DropdownItem to="/nos-staked-details" label="Staked $NOS Details" />
            <DropdownItem to="/stakers-unstakers" label="Stakers & Unstakers" />
          </DropdownMenu>

          {/* Dropdown for Analysis */}
          <DropdownMenu
            label="Analysis"
            icon={<PresentationChartLineIcon className="w-5 h-5" />}
            isOpen={openDropdown === 'analysis'}
            onToggle={() => setOpenDropdown(openDropdown === 'analysis' ? null : 'analysis')}
          >
            <DropdownItem to="/wallet-distributions" label="Wallet Distributions" />
            <DropdownItem to="/raydium-pool" label="Raydium Pool" />
            <DropdownItem to="/richlist" label="Rich List" />
            {/* New NavItem for Node Calc 
            <DropdownItem to="/nosana-node-calc" label="Node Calc" />*/}
          </DropdownMenu>

          {/* Desktop Dark Mode Toggle */}
          <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
        </div>

        {/* Mobile Menu Button and Dark Mode Toggle */}
        <div className="flex items-center md:hidden space-x-4">
          <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
          <button onClick={toggleMenu} className="text-gray-600 dark:text-gray-300 focus:outline-none">
            {isOpen ? <XMarkIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="md:hidden overflow-hidden"
          >
            <nav className="flex flex-col space-y-4 px-4 py-4">
              <NavItem to="/" label="Dashboard" icon={<HomeIcon className="w-5 h-5" />} onClick={toggleMenu} />
              <NavItem to="/nosana-prices" label="Prices" icon={<CurrencyDollarIcon className="w-5 h-5" />} onClick={toggleMenu} />
              <NavItem to="/nosana-volume" label="Volume" icon={<ChartBarIcon className="w-5 h-5" />} onClick={toggleMenu} />
              <NavItem to="/nosana-holders" label="Holders" icon={<UserGroupIcon className="w-5 h-5" />} onClick={toggleMenu} />

              {/* Collapsible Staking Menu for Mobile */}
              <CollapsibleMenu
                label="Staking"
                icon={<ServerStackIcon className="w-5 h-5" />}
                isOpen={openDropdown === 'stakingMobile'}
                onToggle={() => setOpenDropdown(openDropdown === 'stakingMobile' ? null : 'stakingMobile')}
              >
                <NavItem to="/staking-dapp" label="Staking Dapp" onClick={toggleMenu} className="pl-8" />
                <NavItem to="/nos-staked" label="Staked $NOS" onClick={toggleMenu} className="pl-8" />
                <NavItem to="/nos-staked-details" label="Staked $NOS Details" onClick={toggleMenu} className="pl-8" />
                <NavItem to="/stakers-unstakers" label="Stakers & Unstakers" onClick={toggleMenu} className="pl-8" />
              </CollapsibleMenu>

              {/* Collapsible Analysis Menu for Mobile */}
              <CollapsibleMenu
                label="Analysis"
                icon={<PresentationChartLineIcon className="w-5 h-5" />}
                isOpen={openDropdown === 'analysisMobile'}
                onToggle={() => setOpenDropdown(openDropdown === 'analysisMobile' ? null : 'analysisMobile')}
              >
                <NavItem to="/wallet-distributions" label="Wallet Distributions" onClick={toggleMenu} className="pl-8" />
                <NavItem to="/raydium-pool" label="Raydium Pool" onClick={toggleMenu} className="pl-8" />
                <NavItem to="/richlist" label="Rich List" onClick={toggleMenu} className="pl-8" />
                {/*<NavItem to="/nosana-node-calc" label="Node Calc" onClick={toggleMenu} className="pl-8" />*/}
              </CollapsibleMenu>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

// NavItem Component
const NavItem = ({ to, label, icon, onClick, className }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`flex items-center space-x-2 ${
        isActive ? 'text-blue-500 dark:text-blue-400' : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
      } transition-colors duration-200 ${className}`}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
};

// DropdownMenu Component for Desktop
const DropdownMenu = ({ label, icon, isOpen, onToggle, children }) => {
  return (
    <div className="relative">
      <button
        onClick={onToggle}
        className="flex items-center space-x-1 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200 focus:outline-none"
      >
        {icon}
        <span>{label}</span>
        <ChevronDownIcon className={`w-4 h-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute mt-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg z-10"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// DropdownItem Component for Desktop
const DropdownItem = ({ to, label, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <Link
      to={to}
      onClick={onClick}
      className={`block px-4 py-2 ${
        isActive ? 'text-blue-500 dark:text-blue-400' : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800'
      }`}
    >
      {label}
    </Link>
  );
};

// CollapsibleMenu Component for Mobile
const CollapsibleMenu = ({ label, icon, isOpen, onToggle, children }) => {
  return (
    <div>
      <button
        onClick={onToggle}
        className="flex items-center w-full space-x-2 text-left text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200 focus:outline-none"
      >
        {icon}
        <span>{label}</span>
        <ChevronDownIcon className={`w-4 h-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'} ml-auto`} />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <div className="mt-2 space-y-2">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
